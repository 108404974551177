$primary-color: #0098CE;
$white: #fff; 

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700&display=swap');

*{
    font-family: "Inter" !important;
    margin: 0;
    padding: 0;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
    background: $primary-color !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    border-color: $primary-color !important;
}

.flatpickr-weekdays{
    background: $primary-color !important;
}

.flatpickr-input[readonly] {
    padding: .5rem;
    width: 60%;
}

.flatpickr-day .selected{
    color: $primary-color !important;
}

span.flatpickr-weekday{
    background: $primary-color !important;
    color: #fff !important;
}

.flatpickr-months .flatpickr-month{
    background: $primary-color !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    background: $primary-color !important;
}

.flatpickr-input{
    border: none !important;
    color: $primary-color !important;
}

h5{
    color: #000;
}

.home-inner {
    text-align: center;
    & img{
        margin-bottom: 2rem;
    }
    & h1{
        color: #142F59;
        font-weight: bold;
    }
    & p{
        color: #BFBFBF;
        font-weight: bold;
        padding-top: 1rem;
        padding-bottom: 3rem;
    }
}

.logo-lsl{
    padding-top: 1rem;
}

.bg-image{
    width: 100%;
    object-fit: contain;    
}

.open{
    position: absolute;
    top: 30% !important;
}



.modall{
    top: 60%;
    text-align: "center";
    
}

.flatpickr-calendar.open{
    z-index: 3 !important;
}


.react-calendar__month-view__weekdays {
    text-transform: capitalize !important;
    font-weight: 400 !important;
}

abbr[title] {
    text-decoration: none !important;
}

abbr{
    font-size: .85rem !important;
}

.react-calendar {
    border: none !important;
    background-color: #fff !important;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button{
    display: none !important;
}

.react-calendar__tile--active {
    background: $primary-color !important;
    border-radius: 100px;
}

 .react-calendar__navigation__label__labelText{
     font-weight: 600;
     color: #333333;
 }

 a{
    color: $primary-color  !important;
    text-decoration: none !important;
 }

 .required::after {
    content: "*";
    color: red;
}

.containers{
    background-color: #E5E5E5;
    padding-bottom: 2rem;
    min-height: 100vh;

    &-inner{
        width: 90%;
        margin: auto;
        padding-bottom: 2rem;
    }
}

input:focus-visible{
    outline: none !important;
}

*:focus {
    outline: none;
}

.logo{
    text-align: center;
    margin-bottom: 2rem;
    padding-top: 1rem;
}



.calender{
    background-color: #fff;
    padding: 1.5rem 1.1rem 2rem 1.1rem;
    border-radius: .5rem;
    margin: auto;
    width: 100%;
}

.calender h5,
.time h5,
.userInfo h5{
    font-style: normal;
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.time{
    margin-top: 2rem;
    background-color: #fff;
    padding: 1.5rem 1.1rem 2rem 1.1rem;
    border-radius: .5rem;
    & p{
        color: $primary-color ;
        font-size: 1rem;
        padding-bottom: 2rem;
    }

}

.userInfo{
    & p{
        color: $primary-color ;
        font-size: 1rem;
        padding-bottom: 2rem;
    }
}

.timeList{
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    & > *{
        flex: 6;
        min-width: 40%;
        margin: .3rem !important;
        min-height: 60px;
    }
}

.userInfo{
    margin-top: 2rem;
    border-radius: .5rem;
    background-color: #fff;
    padding: 1.5rem 1.1rem 2rem 1.1rem;
}

.card-bodie{
    & label{
        font-size: .85rem;
    }
}

.col-md-6,
.col-md-12{
    margin-bottom: 1rem;
}

.btn-primary {
    color: #fff;
    background-color: $primary-color !important;
    border-color: none !important;
    width: 40%;
}

.meeting-link{
    display: flex;
    justify-content: space-between;
    align-items: center;
    & label:nth-child(1){
        font-size: .8rem;
        align-items: center;
    }
    & label:nth-child(2){
        font-size: .6rem;
        align-items: center;
        color: $primary-color;
    }
}

.userInfo{
    padding-bottom: 2rem;
}

.modalClass{
    padding-top: 30px;
    padding-bottom: 30px;
}

.modal-main{
    width: 80% !important;
    margin: auto !important;
}

#footer {
    position: fixed;
    color: #fff;
    font-size: .8rem;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #3d3c3c;
    color: white;
    text-align: center;
    margin-bottom: 0;
}


@media(min-width: 768px ) and ( max-width: 1023px ){
    .containers{
        &-inner-1{
            display: flex;
            justify-content: space-between;
            margin: auto;
            & > *{
                width: 49%;
            }
        }
    }

    .calender{
        margin: 0;
        // width: 40%;
    }

    .time{
        margin-top: 0;
        overflow: scroll;
        max-height: 419px;
    }

    .userInfo{
        width: 70%;
        margin: 2rem auto auto;
    }
}

@media(min-width: 1024px ){

    .containers{
        min-height: 100vh;
        padding-bottom: 0;
        &-inner-1{
            display: flex;
            justify-content:space-between;
            flex: 2;
            & > *{
                margin: .5rem;
                width: 50%;
            }
        }
        &-inner-2{
            flex: 1.1;
            & > *{
                margin: .5rem;
            }
        }
        &-lg{
            display: flex;
        }

        .time{
            margin-top: 0;
            overflow: scroll;
            max-height:550px;
            padding: 2rem;
        }

        .userInfo{
            overflow: scroll;
            max-height:550px;  
        }
    }
    .open{
            top: 30% !important;
            left: 7% !important;
        }

    .modal-main{
        margin-top: 5rem !important;
        width: 20% !important;
        margin: auto !important;

    }

    .bg-image{
        object-fit: cover;    
        width: 100%;
    }
    
    
    .home-main{
        display: flex;
        width: fit-content;

        align-items: center;
        justify-content: center;
        & > *{
            width: 50%;
        }
    }

    .home-inner{
        padding: 3rem;
        & h1{
            font-size: 50px;
        }
        & p{
            padding-top: 1rem;
            font-size: 1.5rem;
        }
    }
}